<template>
  <div>
    <div class="flix-btn-group">
      <select
        class="flix-form-control"
        v-model="survey.type"
        @change="setSurvey"
      >
        <option value="default">{{ $t("survey.type.default") }}</option>
        <option value="custom">{{ $t("survey.type.custom") }}</option>
        <option value="none">{{ $t("survey.type.none") }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      survey: this.getSurvey()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setSurvey() {
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.survey = this.survey;
      this.$store.commit("business/prefetch", data);
    },
    getSurvey() {
      if (typeof this.$store.state.business.unsaved.survey === "undefined") {
        return { type: "default" };
      } else {
        return JSON.parse(
          JSON.stringify(this.$store.state.business.unsaved.survey)
        );
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
</style>
